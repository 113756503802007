import React, { useLayoutEffect } from 'react';
import { Header, Heading } from '@/layout/Header';
import { Box, Button, Flex, Heading as ChakraHeading, Text } from '@chakra-ui/react';
import FAQAccordion from '@/components/FAQAccordion';
import { platformFAQs } from './constants';

const FAQs = (): React.ReactElement => {
  useLayoutEffect(() => {
    document.title = 'FAQs';
  }, []);

  return (
    <Flex direction="column" w="full" overflowY="auto" overflowX="hidden">
      <Header>
        <Heading>
          <Heading.Title>FAQs</Heading.Title>
        </Heading>
      </Header>

      <Flex direction="column" maxW="844px" w="90%" mt="70" mx="auto" gridGap="16">
        <Flex direction="column" gridGap="4">
          <Box textAlign="center">
            <ChakraHeading fontSize="32px">Frequently Asked Question</ChakraHeading>
            <Text mt="2" fontSize="sm" color="gray.600">
              Discover how to use our platform seamlessly. If you have specific questions or doubts regarding the
              functionality or features, our team is ready to assist,
            </Text>
            <Button
              as="a"
              href="mailto:support@bureau.id"
              mt="4"
              bg="purple.300"
              color="blue.500"
              borderRadius="lg"
              fontWeight="light"
              fontSize="sm"
            >
              Get in Touch
            </Button>
          </Box>
        </Flex>
        <Flex direction="column">
          <FAQAccordion faqs={platformFAQs} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FAQs;
