interface LogoutOptions {
  returnTo: string;
  federated: boolean;
}

export const handleLogout = (logout: (options: LogoutOptions) => void): void => {
  const isSuperAdmin = !!JSON.parse(localStorage.getItem('isSuperAdmin') || 'false');

  window.localStorage.clear();
  if (isSuperAdmin) {
    window.location.href = '/';
  } else {
    logout({ returnTo: `${window.location.origin}/`, federated: false });
  }
};

export const isSuperAdminUser = (): boolean => JSON.parse(localStorage.getItem('isSuperAdmin') || 'false');
