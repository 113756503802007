import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

import { Operators } from '../types';

export const useGetOperators = (): UseQueryResult<Operators, AxiosError> => {
  return useQuery('operators', getOperators(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const getOperators = () => {
  return async () => {
    const isProdBaseUrl = bureauPlatformApi.defaults.baseURL === process.env.REACT_APP_PROD_BASE_URL;
    const baseUrl = !isProdBaseUrl && window.location.href.includes('stg1') ? process.env.REACT_APP_STG1_URL : '';

    const url = baseUrl ? `${baseUrl}/evaluations/operators` : `/evaluations/operators`;

    const res = await bureauPlatformApi.get<Operators>(url);
    return res.data;
  };
};

export default useGetOperators;
