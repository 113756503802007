import React, { ReactElement } from 'react';
import { Flex, Portal } from '@chakra-ui/react';
import FactsLoader from './loader/Loader';

const OverlayLoader = (): ReactElement => {
  return (
    <Portal>
      <Flex
        h="100vh"
        w="100vw"
        bg="rgba(255, 255, 255, 0.5)"
        position="fixed"
        top={0}
        left={0}
        pl="20"
        zIndex="overlay"
        justifyContent="center"
        alignItems="center"
        backdropFilter="blur(2px)"
      >
        <FactsLoader />
      </Flex>
    </Portal>
  );
};

export default OverlayLoader;
