import React, { ReactElement, useMemo } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { Flex, Link, LinkProps, Text } from '@chakra-ui/react';
import { isUrl } from '@/utils/utils';
import { BureauIcon } from './NavbarIcons';
import { NavItemTypes } from './types';
import PlatformTooltip from '../tooltip/PlatformTooltip';

const NavigationBar = ({ navItems }: { navItems: NavItemTypes[] }): ReactElement => {
  const topListItems = useMemo(() => navItems.filter(({ inBottomList }) => !inBottomList), [navItems]);
  const bottomListItems = useMemo(() => navItems.filter(({ inBottomList }) => inBottomList), [navItems]);

  return (
    <Flex h="full" direction="column" width="20" bg="purple.800" alignItems="center" color="gray.50">
      <Flex
        alignItems="center"
        justifyContent="center"
        minH="60px"
        w="full"
        borderLeftWidth={3}
        borderColor="transparent"
      >
        <BureauIcon />
      </Flex>
      {/* Navbar */}
      <Flex as="nav" h="full" w="full" direction="column" justifyContent="space-between" overflow="hidden">
        {/* Top list of Navbar */}
        <Flex w="full" direction="column" gridGap="4" grow={1} overflowY="auto">
          {topListItems.map(item => (
            <NavItem key={item.id} {...item} />
          ))}
        </Flex>
        {/* Bottom list of Navbar */}
        <Flex direction="column" alignItems="center" py="4" gridGap="4" borderTop="1px solid" borderColor="purple.600">
          {bottomListItems.map(item => (
            <FooterItem key={item.id} {...item} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const NavItem = ({ icon, title, path, ...rest }: NavItemTypes): ReactElement => {
  const match = useRouteMatch({
    path,
  });

  // Clone the icon element and pass the weight prop
  const iconWithProps = useMemo(() => React.cloneElement(icon, { weight: match ? 'fill' : undefined }), [icon, match]);

  const selectedStyles: LinkProps = match
    ? {
        borderColor: 'white.100',
        _before: {
          content: '""',
          pos: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          bg: 'white.100',
          opacity: 0.1,
        },
      }
    : {};

  return (
    <Link
      as={RouterLink}
      to={path}
      w="full"
      display="flex"
      flexDirection="column"
      py="2"
      gridGap="1"
      alignItems="center"
      color="white"
      borderLeft="3px solid"
      borderColor="transparent"
      pos="relative"
      _hover={{ color: match ? 'unset' : 'purple.200' }}
      _focus={{ boxShadow: 'none' }}
      {...selectedStyles}
    >
      {iconWithProps}
      <Text fontSize="10px" lineHeight="140%" textAlign="center">
        {title}
      </Text>
    </Link>
  );
};

const FooterItem = ({ icon, title, path, ...rest }: NavItemTypes): ReactElement => {
  const match = useRouteMatch({
    path,
  });

  const commonStyles = {
    color: 'white',
    w: 'fit-content',
    p: '2.5',
    borderRadius: 'lg',
    bg: match ? 'purple.200' : 'purple.600',
    _hover: { bg: 'purple.200' },
    _focus: { boxShadow: 'none' },
  };

  return (
    <PlatformTooltip description={title} tooltipProps={{ placement: 'right', w: 'fit-content' }}>
      {isUrl(path) ? (
        <Link as="a" href={path} target="_blank" {...commonStyles}>
          {icon}
        </Link>
      ) : (
        <Link as={RouterLink} to={path} {...commonStyles}>
          {icon}
        </Link>
      )}
    </PlatformTooltip>
  );
};

export default NavigationBar;
