import React, { ReactElement, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import history from '@/utils/history';
import { useRecoilValue } from 'recoil';
import { EnvToggleAtom } from '@/states/envToggleAtom';
import { bureauPlatformApi, isAuthTokenPresent, setupOrganizationToken, setupSuperAdminAuth } from '@/utils/api';
import { isSuperAdminUser } from '@/utils/auth';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const prod = {
  domain: 'accounts.prism.bureau.id',
  clientId: 'RW5POkfYwww5kJ2gl8ecnYQWQNmIEsni',
  audience: 'https://api.overwatch.bureau.id',
  baseUrl: 'https://api.bureau.id',
};
const test = {
  domain: 'accounts.prism.bureau.id',
  clientId: '65XDlBqKvU44xNMZePG0wR4ocAme9qMD',
  audience: 'https://api.overwatch.stg.bureau.id',
  baseUrl: 'https://api.sandbox.bureau.id',
};

const onRedirectCallback = (appState?: { returnTo?: string; signup_flow?: boolean }): void => {
  history.replace(appState?.returnTo || '/');
};

const AuthenticationProvider = ({ children }: { children: React.ReactNode }): ReactElement => {
  const { search, origin } = window.location;

  const redirectUri = origin === 'https://platform.stg.bureau.id' ? 'https://platform.bureau.id' : `${origin}`;

  const toggledEnv = useRecoilValue(EnvToggleAtom);
  const isProd = toggledEnv === 'live';

  useEffect(() => {
    if (isProd) {
      bureauPlatformApi.defaults.baseURL = prod.baseUrl;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (process.env.REACT_APP_ENV === 'development') {
        bureauPlatformApi.defaults.baseURL = process.env.REACT_APP_BASE_URL;
      } else {
        bureauPlatformApi.defaults.baseURL = test.baseUrl;
      }
    }
  }, [isProd]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const authToken = queryParams.get('authToken');
    const orgId = queryParams.get('orgId');
    if (authToken && orgId) {
      setupSuperAdminAuth(authToken);
      setupOrganizationToken(orgId);
      // Remove query params after setting up auth
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [search]);

  // Skip Auth0 if super-admin token is present
  if (isSuperAdminUser() && isAuthTokenPresent()) {
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN || ''}
      clientId={AUTH0_CLIENT_ID || ''}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={AUTH0_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
