import React, { ReactElement, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Text, useToast, Image, Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import exportFromJSON from 'export-from-json';

import { Header, Heading } from '@/layout/Header';
import { WorkflowJson } from '@/screens/workflows/workflow-creation/states/workflowChart';
import { newJson } from '@/screens/workflows/workflow-creation/constants';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import AlertMessageModal from '@/components/AlertMessageModal';
import { CustomErrorCode, UserAction } from '@/types/common';
import { commonHeaderHeight } from '@/constants/constants';
import useDebounce from '@/hooks/useDebounce';
import { Plus } from 'phosphor-react';
import { RHS, SearchAndFilters } from '@/components/platform-search-filter/SearchAndFilterWrapper';
import { useInfiniteCapabilityList } from './queries/useCapabilityList';
import useCapabilityDetails from './workflow-creation/queries/useCapabilityDetails';
import { ListData, ToastMessage, CapabilityDataTypes, Capability } from './types';
import { refetchDelay } from './constants';
import useCapabilityDeletion from './workflow-creation/queries/useCapabilityDeletion';
import AddDuplicateWorkflowModal from './workflow-creation/components/AddDuplicateWorkflowModal';
import TryOutModal from './components/try-out-modal/TryOutModal';
import { useTryOut } from './queries/useTryOut';
import { Parameter } from './components/try-out-modal/types';
import WorkFlowListContent from './components/WorkflowListContent';
import SaveCapabilityModal from './workflow-creation/components/SaveCapabilityModal';
import useCapabilityUpdation from './workflow-creation/queries/useCapabilityUpdation';
import useCapabilityCreation from './queries/useCapabilityCreation';
import { generateUUID } from '../workflowv2/utils';

export const initialWorkflowDetails: Capability = {
  id: '',
  name: '',
  description: '',
  plansWithAccess: [],
  industry: [],
  geography: [],
  useCase: [],
  active: false,
  input: [],
  output: [],
  createdAt: 0,
  updatedAt: 0,
};

export const initialTryOutDetails: Parameter[] = [{ parameter: '', value: '', type: '' }];

export const CapabilitiesList = (): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [nameExistsError, setNameExistsError] = useState(false);
  const [workflowDetails, setWorkflowDetails] = useState<Capability>(initialWorkflowDetails);
  const [tryOutDetails, setTryOutDetails] = useState(initialTryOutDetails);
  const [tryOutResponse, setTryOutResponse] = useState('');
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useLayoutEffect(() => {
    document.title = 'Capabilities';
  }, []);

  const searchItem = useDebounce(searchTerm);

  const { data, isLoading, isFetching, isError, refetch, fetchNextPage, hasNextPage } = useInfiniteCapabilityList(
    true,
    undefined,
    searchItem,
  );
  const history = useHistory();
  const toast = useToast();

  const { data: workflowObject, refetch: refetchWorkflowDetails, isFetching: isDetailsFetching } = useCapabilityDetails(
    workflowDetails.id,
    false,
  );

  const { listData, count } = data ? (data as ListData) : { listData: [], count: 0 };

  const { mutateAsync: createCapability, isLoading: isCapabilityCreationLoading } = useCapabilityCreation({
    onSuccess: async response => {
      setIsButtonLoading(false);
      setWorkflowJson(newJson);
      history.push(`/capabilities/${response.id}/edit`);
    },
    onError: mutateError => handleModifyWorkflowError(mutateError),
  });

  const { mutateAsync: updateCapability, isLoading: updateCapabilityLoading } = useCapabilityUpdation({
    onSuccess: () => handleSuccess(ToastMessage.CAPABILITY_UPDATED_MESSAGE),
    onError: updateError => handleModifyWorkflowError(updateError),
  });

  const { mutateAsync: tryOutWorkflow } = useTryOut({
    onSuccess: response => {
      setTryOutResponse('success');
      setTransactionId(response?.transactionId);
      setSelectedAction('');
      setTryOutDetails(initialTryOutDetails);
    },
    onError: () => {
      setTryOutResponse('error');
      setSelectedAction('');
    },
  });

  const handleModifyWorkflowError = (updateError: AxiosError): void => {
    if (updateError?.response?.status === CustomErrorCode.RECORD_EXISTS) {
      setNameExistsError(true);
      setIsButtonLoading(false);
    } else {
      handleErrorToast(updateError);
    }
    setIsButtonLoading(false);
  };

  const { mutateAsync: deleteWorkflow } = useCapabilityDeletion({
    onSuccess: () => {
      handleSuccess(ToastMessage.CAPABILITY_DELETED_MESSAGE);
    },
    onError: deleteError => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleErrorToast(deleteError);
    },
  });

  const handleSuccess = (toastMessage: string): void => {
    setTimeout(() => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleSuccessToast(toastMessage);
      refetch();
    }, refetchDelay);
  };

  const setWorkflowJson = useSetRecoilState(WorkflowJson);

  const handleSuccessToast = (description: string): void => {
    toast({
      description,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleErrorToast = (mutateError: AxiosError): void => {
    toast({
      description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const onDuplicate = (details: Capability): void => {
    const processedCapabilityObject = details.object?.columns
      .map(column => ({
        ...column,
        id: `column_${generateUUID()}`,
      }))
      .map(column => {
        return {
          ...column,
          nodes: column.nodes.map(node => {
            const nodeId = `${node.id}${generateUUID()}`;
            return {
              ...node,
              id: nodeId,
              outputs: node.outputs?.map(output => ({
                ...output,
                namespace: nodeId,
              })),
            };
          }),
        };
      });
    createCapability({ ...details, object: { columns: processedCapabilityObject || [] } });
  };

  const onSaveCapability = (details: CapabilityDataTypes): void => {
    updateCapability(details);
    setIsButtonLoading(true);
  };

  const onActionPopupCancel = (): void => {
    setSelectedAction('');
    setWorkflowDetails(initialWorkflowDetails);
  };

  const onDelete = (): void => {
    if (workflowDetails?.id) {
      deleteWorkflow({ id: workflowDetails?.id });
      setIsButtonLoading(true);
    }
  };

  const getTryOutDetails = (): { [key: string]: string } => {
    let response = {};
    tryOutDetails.forEach((parameter: Parameter) => {
      response = { ...response, [parameter.parameter]: parameter.value };
    });
    return response;
  };

  const onTryOutWorkflow = (): void => {
    const request = {
      workflowId: workflowDetails.id,
      data: { ...getTryOutDetails() },
    };
    tryOutWorkflow(request);
  };

  const onCreateWorkflow = (): void => {
    history.push(`/capabilities/create`);
    setWorkflowJson(newJson);
  };

  useEffect(() => {
    if (workflowObject?.id && !isDetailsFetching && isExportButtonClicked) {
      exportFromJSON({
        data: workflowObject?.object,
        fileName: workflowObject?.name,
        exportType: exportFromJSON?.types.json,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowObject, isDetailsFetching, isExportButtonClicked]);

  useEffect(() => {
    if (selectedAction === UserAction.EXPORT && workflowDetails.id) {
      setIsExportButtonClicked(true);
      refetchWorkflowDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction, workflowDetails]);

  const getResponseContent = useMemo(
    (): ReactElement => (
      <Flex direction="column" alignItems="center">
        <Image src={`/assets/icons/${tryOutResponse}_response.svg`} mb={4} />
        <Box textAlign="center" mt={2}>
          {tryOutResponse === 'success'
            ? 'Transaction successfully executed'
            : 'Some error occurred while executing the transaction. Please try again.'}
        </Box>
      </Flex>
    ),
    [tryOutResponse],
  );

  return (
    <Flex direction="column" w="full" overflowY="auto" overflowX="hidden">
      <Header>
        <Heading>
          <Heading.Title>Capability</Heading.Title>
          <Heading.SubHeader>({count} items)</Heading.SubHeader>
        </Heading>
      </Header>

      <Flex direction="column" gridGap="5" px="5" py="5" h={`calc(100% - ${commonHeaderHeight}px)`}>
        <SearchAndFilters setSearchTerm={setSearchTerm}>
          <RHS>
            {/* <RHS.Filters>
            // Active and Inactive filter
            </RHS.Filters> */}
            <RHS.Buttons>
              <Button
                leftIcon={<Plus />}
                bg="purple.300"
                color="blue.500"
                borderRadius="lg"
                fontWeight="light"
                fontSize="sm"
                isLoading={isCapabilityCreationLoading}
                onClick={onCreateWorkflow}
                id="create-capability"
              >
                New Capability
              </Button>
            </RHS.Buttons>
          </RHS>
        </SearchAndFilters>

        <Box h="calc(100% - 60px)" overflowY="auto">
          <WorkFlowListContent
            data={listData}
            isFetching={isFetching}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
            onCreateWorkflow={onCreateWorkflow}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            setSelectedAction={setSelectedAction}
            setWorkflowDetails={setWorkflowDetails}
          />
        </Box>
      </Flex>

      {selectedAction === UserAction.EDIT && (
        <SaveCapabilityModal
          isOpen={selectedAction === UserAction.EDIT}
          setIsOpen={() => setSelectedAction('')}
          details={workflowDetails}
          isLoading={updateCapabilityLoading || isButtonLoading}
          onSaveCapability={onSaveCapability}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
        />
      )}
      {selectedAction === UserAction.DELETE && (
        <ConfirmationModal
          headerText="Delete Workflow"
          bodyText={
            <Box fontSize="sm" display="flex">
              Do you really want to delete{' '}
              <Text ml={1} color="blue.500" fontWeight={600} textDecoration="underline">
                {workflowDetails?.name}?
              </Text>
            </Box>
          }
          isLoading={isButtonLoading}
          onClose={onDelete}
          onCancel={onActionPopupCancel}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      )}
      {selectedAction === UserAction.DUPLICATE && (
        <AddDuplicateWorkflowModal
          details={workflowDetails}
          onClose={onDuplicate}
          onCancel={onActionPopupCancel}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
        />
      )}
      {selectedAction === UserAction.TRYOUT && (
        <TryOutModal
          onCancel={() => {
            setTryOutDetails(initialTryOutDetails);
            onActionPopupCancel();
          }}
          onClose={onTryOutWorkflow}
          workflowId={workflowDetails.id}
          tryOutDetails={tryOutDetails}
          setTryOutDetails={setTryOutDetails}
        />
      )}
      {tryOutResponse && (
        <AlertMessageModal
          alertContent={getResponseContent}
          onCancel={() => setTryOutResponse('')}
          onClose={() => {
            if (tryOutResponse === 'success') {
              history.push(`/reports/${transactionId}`);
            } else setTryOutResponse('');
          }}
          {...(tryOutResponse === 'success' && { buttonLabel: 'View Transaction' })}
        />
      )}
    </Flex>
  );
};

export default CapabilitiesList;
