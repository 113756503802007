import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { TransactionDetail } from '@/screens/transactions/transaction-details/types';

interface TryOutRequest {
  workflowId?: string;
  templateId?: string;
  data: {
    [key: string]: string | boolean | string[];
  };
}

export const useTryOut = (
  options?: UseMutationOptions<TransactionDetail, AxiosError, TryOutRequest>,
): UseMutationResult<TransactionDetail, AxiosError, TryOutRequest> => {
  return useMutation((data: TryOutRequest) => tryOut(data), options);
};

const tryOut = async (data: TryOutRequest): Promise<TransactionDetail> => {
  const isProdBaseUrl = bureauPlatformApi.defaults.baseURL === process.env.REACT_APP_PROD_BASE_URL;
  const baseUrl = !isProdBaseUrl && window.location.href.includes('stg1') ? process.env.REACT_APP_STG1_URL : '';

  const url = baseUrl ? `${baseUrl}/transactions` : `/transactions`;

  const res = await bureauPlatformApi.post<TransactionDetail>(url, data);
  return res.data;
};
