import { useState } from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import useDebounce from '@/hooks/useDebounce';
import { Row } from 'react-table';
import { useAuthDetails } from '@/queries/useAuth';
import { bureauPlatformApi } from '@/utils/api';
import { useRecoilValue } from 'recoil';
import { EnvToggleAtom } from '@/states/envToggleAtom';
import { useHistory } from 'react-router-dom';
import useFetchQuickLink from './useFetchQuickLink';
import useUpdateQuickLink from './useUpdateQuickLink';
import { QuickLinkData } from '../workflowv2/types';
import useQuickLinkDelete from './useQuickLinkDelete';
import { useGenerateSDKToken } from './useFetchToken';

const DEFAULT_PAGE_LIMIT = '10';

interface UseQuickLinksReturn {
  data: QuickLinkData[];
  totalCount: number;
  isLoading: boolean;
  searchTerm: string;
  limit: string;
  offset: number;
  setSearchTerm: (term: string) => void;
  setLimit: (limit: string) => void;
  setOffset: (offset: number) => void;
  handleToggle: (id: string) => void;
  handleActionButtonClick: (row: Row, type: string) => void;
  isOpen: boolean;
  onClose: () => void;
  url: string;
  history: ReturnType<typeof useHistory>;
}

export const useQuickLinks = (): UseQuickLinksReturn => {
  const history = useHistory();
  const toggledEnv = useRecoilValue(EnvToggleAtom);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_PAGE_LIMIT);
  const [searchTerm, setSearchTerm] = useState('');
  const [url, setURL] = useState('');
  const searchItem = useDebounce(searchTerm);
  const toast = useToast();
  const { data: authDetails } = useAuthDetails();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: token } = useGenerateSDKToken();

  const { data, isLoading, isFetching, refetch: refetchQuickLink } = useFetchQuickLink({
    search: searchItem,
    limit: Number(limit),
    offset: Number(offset),
  });

  const { mutate: updateQuickLink } = useUpdateQuickLink({
    onSuccess: () => {
      refetchQuickLink();
      toast({
        title: 'Link Updated',
        description: 'QuickLink has been updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: error => {
      toast({
        title: 'Error',
        description: error.message || 'Failed to generate QuickLink',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const { mutate: deleteQuickLink } = useQuickLinkDelete({
    onSuccess: () => {
      refetchQuickLink();
      toast({
        title: 'Delete Quicklink',
        description: 'QuickLink has been deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: error => {
      toast({
        title: 'Error',
        description: error.message || 'Failed to delete QuickLink',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleToggle = (id: string): void => {
    const quickLink = data?.data.find(q => q.id === id) as QuickLinkData;
    if (!quickLink) return;

    updateQuickLink({
      id,
      data: {
        ...quickLink,
        isEnabled: !quickLink.isEnabled,
      },
    });
  };

  let baseUrl: string | undefined;
  if (toggledEnv === 'live') {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
  } else {
    baseUrl = authDetails?.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : bureauPlatformApi.defaults.baseURL;
  }

  function generateQuickLinkURL(quicklinkId: string, workflowId: string, sdkToken: string): string {
    const options = {
      workflowId,
      type: 'INIT_DATA',
      env: process.env.NODE_ENV,
      baseUrl,
      quicklinkId,
      webt: `Token ${sdkToken}`,
      orgId: bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
    };
    const encodedOptions = window.btoa(JSON.stringify(options));
    return `${process.env.REACT_APP_TRYOUT_SDK_URL}/?options=${encodedOptions}`;
  }

  const handleActionButtonClick = (row: Row, type: string): void => {
    if (type === 'DELETE') {
      deleteQuickLink({ id: row.values.id });
    } else if (type === 'COPY') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values = row.original as any;
      const sdkURL = generateQuickLinkURL(values.id, values.workflowId, values.sdkToken);
      navigator.clipboard.writeText(sdkURL);
      toast({
        title: 'Copy',
        description: 'QuickLink has been copied successfully',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } else if (type === 'SHOW_QRCODE') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values = row.original as any;
      const sdkURL = generateQuickLinkURL(values.id, values.workflowId, values.sdkToken);
      setURL(sdkURL);
      onOpen();
    }
  };

  return {
    data: data?.data.map(ql => ({ ...ql, sdkToken: token })) || [],
    totalCount: data?.count || 0,
    isLoading: isLoading || isFetching,
    searchTerm,
    limit,
    offset,
    setSearchTerm,
    setLimit,
    setOffset,
    handleToggle,
    handleActionButtonClick,
    isOpen,
    onClose,
    url,
    history,
  };
};
