import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { bureauPlatformApi } from '@/utils/api';
import { SortField } from '@/types/common';
import { BlocklistApiResponse, BlocklistFilterField } from '../types';
import { getSearchTerm, getSortField, getFilterField } from '../helpers/parameters';

export const useBlocklist = (
  enabled = true,
  sortField?: SortField,
  filterFields?: BlocklistFilterField,
  searchTerm?: string,
  offset?: number,
  limit?: string,
): UseQueryResult<BlocklistApiResponse, AxiosError> =>
  useQuery(
    ['/blocklists', 'blocklist', filterFields, sortField, searchTerm, offset, limit],
    getBlocklist(offset || 0, sortField, filterFields, searchTerm, limit),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled,
      select: response => {
        const data = {
          listData: response?.blocklists,
          count: response?.blocklists?.length ?? 0,
        };
        return data;
      },
    },
  );

const getBlocklist = (
  pageParam: number,
  sortField?: SortField,
  filterFields?: BlocklistFilterField,
  searchTerm?: string,
  limit = '0',
) => {
  return async () => {
    const params = {
      limit: +limit,
      offset: pageParam * +limit,
      sortBy: getSortField(sortField),
      ...(searchTerm && { searchTerm: getSearchTerm(searchTerm) }),
      ...(checkIfFilterApplied(filterFields) && { filter: getFilterField(filterFields) }),
    };

    const res = await bureauPlatformApi.get<BlocklistApiResponse>('/blocklists', {
      params,
    });
    return res?.data;
  };
};

const checkIfFilterApplied = (filterFields?: BlocklistFilterField): boolean => {
  return (
    (filterFields?.outcome && filterFields.outcome.length && filterFields.outcome[0] !== '') ||
    !!filterFields?.createdAt?.length
  );
};
