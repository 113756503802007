import axios from 'axios';

const getBaseUrl = (): string => {
  const BASE_URL = process.env.REACT_APP_BASE_URL || '';
  const isDev: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  return isDev ? 'https://api.overwatch.dev.bureau.id' : BASE_URL;
};

const bureauPlatformApi = axios.create({
  baseURL: getBaseUrl(),
});

const setupAuth = (token: string): void => {
  if (token) bureauPlatformApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  // setupOrganizationToken('org_FB0klRDEyc1ChBDF');
};

const setupOrganizationToken = (orgId: string): void => {
  bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'] = orgId;
};

const isAuthTokenPresent = (): boolean => {
  return !!bureauPlatformApi.defaults.headers.common.Authorization;
};

const setupSuperAdminAuth = (token: string): void => {
  if (token) bureauPlatformApi.defaults.headers.common.Authorization = `CustomJWT ${token}`;
  localStorage.setItem('isSuperAdmin', 'true');
};

export { getBaseUrl, bureauPlatformApi, setupAuth, setupOrganizationToken, isAuthTokenPresent, setupSuperAdminAuth };
