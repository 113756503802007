import { AxiosError } from 'axios';
import { useQuery, UseQueryResult, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { GeneratedKeys, OnboardingList } from '@/types/common';
import { UserOrg } from '@/states/userListAtom';
import { useRecoilState } from 'recoil';

export const useAdminKeyDetails = (): UseQueryResult<OnboardingList[], AxiosError> => {
  const userOrg = useRecoilState(UserOrg);
  return useQuery(['/admin/api-keys', 'authList', userOrg], fetchAdminKeyDetails(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!userOrg.length,
  });
};

const fetchAdminKeyDetails = () => {
  return async () => {
    const res = await bureauPlatformApi.get('v1/auth/list');
    return res.data;
  };
};

export const useAuthKeyCreation = (
  options?: UseMutationOptions<GeneratedKeys, AxiosError, { app_name: string }>,
): UseMutationResult<GeneratedKeys, AxiosError, { app_name: string }> => useMutation(getAuthKey, options);

const getAuthKey = async ({ app_name }: { app_name: string }): Promise<GeneratedKeys> => {
  const { data: response } = await bureauPlatformApi.post('v1/auth/generate', { app_name });
  return response;
};

export const useDeleteApikey = (
  options?: UseMutationOptions<GeneratedKeys, AxiosError, { credentialId: string }>,
): UseMutationResult<GeneratedKeys, AxiosError, { credentialId: string }> => useMutation(deleteApikey, options);

const deleteApikey = async (data: { credentialId: string }): Promise<GeneratedKeys> => {
  const { data: response } = await bureauPlatformApi.delete(`/v1/auth/revoke/${data?.credentialId}`, {});
  return response || data;
};

export const useToggleApikey = (
  options?: UseMutationOptions<GeneratedKeys, AxiosError, { credentialId: string }>,
): UseMutationResult<GeneratedKeys, AxiosError, { credentialId: string }> => useMutation(toggleApikey, options);

const toggleApikey = async (data: { credentialId: string }): Promise<GeneratedKeys> => {
  const { data: response } = await bureauPlatformApi.put(`/v1/auth/toggleCred/${data?.credentialId}`, {});
  return response || data;
};
