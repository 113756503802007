import React, { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { Header, Heading } from '@/layout/Header';
import { commonHeaderHeight } from '@/constants/constants';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { CaretRight } from 'phosphor-react';
import { columns } from './columns';
import { useQuickLinks } from './useQuickLink';
import QRCodeModal from './QRCodeModal';

export const QuickLinksHome = (): ReactElement => {
  const {
    data,
    totalCount,
    isLoading,
    limit,
    offset,
    setLimit,
    setOffset,
    handleToggle,
    handleActionButtonClick,
    isOpen,
    onClose,
    url,
    history,
  } = useQuickLinks();

  return (
    <Flex direction="column" w="full" overflowY="auto" overflowX="hidden">
      <Header>
        <Heading>
          <Heading.Title>
            <Breadcrumb spacing="8px" separator={<CaretRight style={{ color: 'gray.500' }} size={18} />}>
              <BreadcrumbItem color="gray.500">
                <BreadcrumbLink onClick={() => history.replace('/workflows/my-workflows')}>Workflows</BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem color="gray.900" isCurrentPage>
                <span>QuickLinks</span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Heading.Title>
        </Heading>
      </Header>
      <Box px="5" py="5" h={`calc(100% - ${commonHeaderHeight}px)`}>
        <Box h="calc(100% - 60px)" mt="4" overflowY="auto">
          <Flex direction="column" h="100%">
            <PaginatedTable
              data={data}
              columns={columns(handleToggle, handleActionButtonClick)}
              isLoading={isLoading}
              title="quicklinks"
              offset={offset}
              count={totalCount}
              limit={String(limit)}
              setLimit={setLimit}
              setOffset={setOffset}
            />
          </Flex>
        </Box>
      </Box>
      <QRCodeModal url={url} isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default QuickLinksHome;
