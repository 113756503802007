import React, { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { Book } from 'phosphor-react';
import { useDocumentation } from '@/queries/UseDocumentation';

const APIDocumentationButton = (): ReactElement => {
  const { data: docPath } = useDocumentation();

  return (
    <Button
      variant="outline"
      size="sm"
      leftIcon={<Book size={18} />}
      colorScheme="blue"
      borderColor="blueGray.100"
      borderRadius="lg"
      color="blue.400"
      fontSize="sm"
      fontWeight="light"
      h="9"
      onClick={() => window.open(docPath?.url, '_blank')}
      isDisabled={!docPath}
    >
      API Documentation
    </Button>
  );
};

export default APIDocumentationButton;
