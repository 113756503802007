import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { SubscriptionDetails } from '../types';

export const useFetchActiveSubscription = (): UseQueryResult<SubscriptionDetails, AxiosError> => {
  return useQuery('get-get-active-subscription', getClientSecret(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const getClientSecret = () => {
  return async () => {
    const isProdBaseUrl = bureauPlatformApi.defaults.baseURL === process.env.REACT_APP_PROD_BASE_URL;
    const baseUrl = !isProdBaseUrl && window.location.href.includes('stg1') ? process.env.REACT_APP_STG1_URL : '';

    const url = baseUrl ? `${baseUrl}/suppliers/subscription` : `/suppliers/subscription`;

    const res = await bureauPlatformApi.get<SubscriptionDetails>(url);
    return res.data;
  };
};
export default useFetchActiveSubscription;
