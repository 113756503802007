import { colors } from '@/utils/colors';
import { BulkQueryStatusValue } from './types';

export const headerHeight = 72;

export const breadcrumbSectionHeight = 56;

export const bottomMargin = 30;

export const bulkVerificationFooter = 88;

export const downloadOptions = [
  { name: 'Success Response CSV', type: 'okResponseCSV' },
  { name: 'Success Response JSON', type: 'okResponseJson' },
  { name: 'Error Response CSV', type: 'errorResponseCSV' },
  { name: 'Error Response JSON', type: 'errorResponseJson' },
];

export const statusDisplayNameMap = new Map([
  [BulkQueryStatusValue.DONE, 'Completed'],
  [BulkQueryStatusValue.CANCELLED, 'Stopped'],
  [BulkQueryStatusValue.UPLOADING, 'Draft'],
  [BulkQueryStatusValue.PROCESSING, 'In Progress'],
  [BulkQueryStatusValue.FAILED, 'Failed'],
  [BulkQueryStatusValue.CANCELLING, 'Stopping'],
]);

export const statusOptions = [
  {
    label: statusDisplayNameMap.get(BulkQueryStatusValue.PROCESSING) ?? '',
    value: BulkQueryStatusValue.PROCESSING,
  },
  {
    label: statusDisplayNameMap.get(BulkQueryStatusValue.DONE) ?? '',
    value: BulkQueryStatusValue.DONE,
  },
  {
    label: statusDisplayNameMap.get(BulkQueryStatusValue.FAILED) ?? '',
    value: BulkQueryStatusValue.FAILED,
  },
  {
    label: statusDisplayNameMap.get(BulkQueryStatusValue.CANCELLED) ?? '',
    value: BulkQueryStatusValue.CANCELLED,
  },
];

export const statusCodeColors: { [key: string]: string } = {
  '200': colors.green[500],
  '201': colors.green[600],
  '202': colors.green[200],
  '400': colors.orange[500],
  '401': colors.orange[100],
  '403': colors.terracotta[50],
  '404': colors.terracotta[100],
  '422': colors.terracotta[500],
  '429': colors.orange[100],
  '500': colors.red[500],
  '501': colors.red[400],
  '502': colors.red[50],
  '503': colors.red[100],
  '504': colors.red[200],
  '600': colors.purple[200],
};

export const nonProgressPercentMerchant = ['org_wCM2x0wxzGERUFLu'];
