import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { OnboardingInputData } from '../types';

export const useCreateOrganization = (
  options?: UseMutationOptions<{}, AxiosError, {}>,
): UseMutationResult<{}, AxiosError, OnboardingInputData> => useMutation(createOrganization, options);

const createOrganization = async (organizationDetails: OnboardingInputData): Promise<{ organisation_id?: string }> => {
  const res = await bureauPlatformApi.post('v1/auth/create-organisation', {
    ...organizationDetails,
    tncAccepted: 'true',
  });
  return res.data;
};
