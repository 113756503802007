import { bureauPlatformApi } from '@/utils/api';
import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

interface TokenResponse {
  token: string;
}

interface TokenError {
  status: 'error';
  message: string;
}

export const useGenerateSDKToken = (): UseQueryResult<string, AxiosError<TokenError>> => {
  return useQuery(['sdkToken', '/quicklinks'], generateSDKToken(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: data => data.token,
  });
};

const generateSDKToken = () => {
  return async () => {
    const res = await bureauPlatformApi.get<TokenResponse>('/v1/auth/generate-sdk-token');
    return res.data;
  };
};
