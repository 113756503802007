import React from 'react';
import { FeatureFlags } from '@/types/common';
import { CircleWavyQuestion, Phone } from 'phosphor-react';
import {
  APIHealthIcon,
  APIIcon,
  BulkQueryIcon,
  CapabilityIcon,
  DashboardIcon,
  DemoIcon,
  InsightsIcon,
  TransactionIcon,
  WorkflowIcon,
} from './NavbarIcons';
import { NavItemTypes } from './types';

export const MENU_ITEMS: NavItemTypes[] = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    id: 'dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'Workflow',
    path: '/workflows',
    id: 'workflows',
    icon: <WorkflowIcon />,
  },
  {
    title: 'Capability',
    path: '/capabilities',
    id: 'customCapabilities',
    icon: <CapabilityIcon />,
  },
  {
    title: 'APIs',
    path: '/documentation',
    id: 'docs',
    icon: <APIIcon />,
  },
  {
    title: 'Transaction',
    path: '/reports',
    id: 'transactions',
    icon: <TransactionIcon />,
  },
  {
    title: 'Insights',
    path: '/insights',
    id: 'insights',
    icon: <InsightsIcon />,
  },
  {
    title: 'Bulk Query',
    path: '/bulk-query',
    id: 'bulkVerification',
    icon: <BulkQueryIcon />,
  },
  {
    title: 'Demo',
    path: '/demos',
    id: 'demo',
    icon: <DemoIcon />,
  },
  {
    title: 'Contact Sales',
    path: 'https://bureau.id/contact-us',
    id: 'contactUs',
    icon: <Phone size={20} />,
    inBottomList: true,
  },
  {
    title: 'API Health',
    path: 'https://status.bureau.id',
    id: 'apiHealth',
    icon: <APIHealthIcon />,
    inBottomList: true,
  },
  {
    title: 'FAQs',
    path: '/faqs',
    id: 'faqs',
    icon: <CircleWavyQuestion size={20} />,
    inBottomList: true,
  },
];

export const FEATURE_FLAG_CHECK_BYPASSED_MENU_ITEMS = ['lists'] as (keyof FeatureFlags)[];
