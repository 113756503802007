import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

interface TryOutRequest {
  workflowId?: string;
  data: {
    [key: string]: string;
  };
}

interface TryOutResponse {
  transactionId: string;
}

export const useTryOut = (
  options?: UseMutationOptions<TryOutResponse, AxiosError, TryOutRequest>,
): UseMutationResult<TryOutResponse, AxiosError, TryOutRequest> => {
  return useMutation((data: TryOutRequest) => tryOut(data), options);
};

const tryOut = async (data: TryOutRequest): Promise<TryOutResponse> => {
  const isProdBaseUrl = bureauPlatformApi.defaults.baseURL === process.env.REACT_APP_PROD_BASE_URL;
  const baseUrl = !isProdBaseUrl && window.location.href.includes('stg1') ? process.env.REACT_APP_STG1_URL : '';

  const url = baseUrl ? `${baseUrl}/transactions` : '/transactions';
  const { data: response } = await bureauPlatformApi.post<TryOutResponse>(url, data);
  return response;
};
