import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { AuthDetails, GeneratedKeys, OnboardingList } from '../types/common';

export const useAuth = (): UseQueryResult<OnboardingList[], AxiosError> => {
  return useQuery('auth', getFetchAuthList(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const getFetchAuthList = () => {
  return async () => {
    const res = await bureauPlatformApi.get('v1/auth/list');
    return res.data;
  };
};

export const useAuthKey = (
  options?: UseMutationOptions<GeneratedKeys, AxiosError, {}>,
): UseMutationResult<GeneratedKeys, AxiosError, {}> => {
  return useMutation(getAuthKey, {
    retry: 3,
    retryDelay: attempt => attempt * 1000,
    ...options,
  });
};

const getAuthKey = async (): Promise<GeneratedKeys> => {
  const { data: response } = await bureauPlatformApi.post('v1/auth/generate', {});
  return response;
};

export const useAuthDetails = (): UseQueryResult<AuthDetails, AxiosError> => {
  return useQuery(['authDetails', '/settings/team-settings', '/settings/profile-n-organisation'], fetchAuthDetails(), {
    retry: 3,
    retryDelay: attempt => attempt * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    enabled:
      !!bureauPlatformApi.defaults.headers.common.Authorization &&
      !!bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
  });
};

const fetchAuthDetails = () => {
  return async () => {
    const res = await bureauPlatformApi.get('v1/auth/me');
    return res.data;
  };
};
