import { Flex, VStack, Text, Image, Button } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { handleLogout } from '@/utils/auth';
import Header from './components/Header';
import { Footer } from './components/Footer';

function VerifyEmail(): ReactElement {
  const { logout } = useAuth0();
  return (
    <>
      <Header />
      <VStack spacing={4} h="100vh" justifyContent="center">
        <Image src="/assets/artEmptyState.svg" height={48} />
        <Flex direction="column" align="center">
          <Text fontSize="sm" padding="5px">
            Thank you for signing up!
          </Text>
          <Text fontSize="3xl" fontWeight="bold" padding="5px">
            Verify Your Email Address!{' '}
          </Text>
          <Text fontSize="sm" color="gray.600" padding="5px" textAlign="center">
            Please check your email and click on the <br /> verification link to continue.
          </Text>
        </Flex>

        <Button
          colorScheme="blue"
          px="10"
          py="3"
          fontSize="sm"
          fontWeight="300"
          onClick={(): void => handleLogout(logout)}
        >
          Go Back
        </Button>
      </VStack>
      <Footer />
    </>
  );
}

export default VerifyEmail;
