import React, { ReactElement, useEffect } from 'react';
import { Box, Flex, Switch, Text, Tooltip, Button, Divider } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { EnvToggleAtom } from '@/states/envToggleAtom';

import { AddIcon } from '@chakra-ui/icons';
import { colors } from '@/utils/colors';
import { useAuthDetails } from '@/queries/useAuth';
import { containsUUID, redirectToContactUs } from '@/utils/utils';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV_TOGGLE_BLACKLIST } from '@/constants/constants';
import useDecodedToken from '@/hooks/useDecodedToken';
import useGetUsageMetrics from './trial-banner/queries/useGetUsageMetrics';

const borderTopWidth = 3;
const transitionDelay = 1;

const EnvironmentToggle = (): ReactElement => {
  const { userDetails } = useDecodedToken();
  const { data: usageData = { monthlyAllocated: 0, monthlyUsage: 0 } } = useGetUsageMetrics();
  const { isLiveEnabled } = useEnvironmentToggle();

  const [toggledEnv, setToggledEnv] = useRecoilState(EnvToggleAtom);

  const isLiveMode = toggledEnv === 'live';
  const canLiveModeBeEnabled = isLiveEnabled;

  const creditsRemaining = usageData?.monthlyAllocated - usageData?.monthlyUsage;
  const credits = creditsRemaining > 0 ? creditsRemaining : 0;

  useEffect(() => {
    const storedEnv = localStorage.getItem('toggledEnv');
    if (!storedEnv) {
      const env = canLiveModeBeEnabled ? 'live' : 'test';
      setToggledEnv(env);
      localStorage.setItem('toggledEnv', env);
    }
  }, [canLiveModeBeEnabled, setToggledEnv]);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const env = event.target.checked ? 'live' : 'test';
    setToggledEnv(env);
    localStorage.setItem('toggledEnv', env);
  };

  const onAddCredits = (): void => {
    const message = 'Increase testing credits for Bureau Platform';
    redirectToContactUs(userDetails, message);
  };

  const requestLiveAccess = (): void => {
    const message = 'Request to enable Live access for Bureau Platform';
    redirectToContactUs(userDetails, message);
  };

  return (
    <Box
      borderTopWidth={3}
      borderColor={isLiveMode ? 'blue.300' : 'terracotta.500'}
      position="absolute"
      inset={0}
      zIndex={1301} // above overlay loader
      pointerEvents="none"
    >
      <Box
        position="relative"
        transition={`max-width ${transitionDelay}s ease`}
        maxW={(isLiveMode ? 138 : 264) - borderTopWidth}
        mx="auto"
        h="8"
      >
        <Box
          h="8"
          position="relative"
          bg={isLiveMode ? 'blue.300' : 'terracotta.500'}
          transition={`background-color ${transitionDelay}s ease`}
          _before={{
            content: "''",
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '111%',
            backgroundColor: isLiveMode ? 'blue.300' : 'terracotta.500',
            borderRadius: '0 0 8px 8px',
            transform: 'perspective(100px) rotateX(-20deg)',
            transformOrigin: 'bottom',
            transition: `background-color ${transitionDelay}s ease`,
          }}
        >
          <Flex
            h="full"
            justifyContent="center"
            alignItems="center"
            position="relative"
            gridGap="2.5"
            pointerEvents="auto"
          >
            <Flex align="center" gridGap={2}>
              <Text color="white" fontSize="xs" whiteSpace="nowrap" textTransform="capitalize">
                {`${toggledEnv} Mode`}
              </Text>
              <Tooltip
                label={
                  <Flex maxW="190" fontSize="xs" fontWeight="light">
                    {canLiveModeBeEnabled ? (
                      <Text>Switch between test and live mode</Text>
                    ) : (
                      <Text>
                        Your account is in test mode, to enable live mode{' '}
                        <Button
                          variant="link"
                          fontSize="xs"
                          fontWeight="medium"
                          color="blue.500"
                          onClick={requestLiveAccess}
                        >
                          contact
                        </Button>{' '}
                        our sales team
                      </Text>
                    )}
                  </Flex>
                }
                offset={[45, 15]}
                placement="bottom-end"
                p="3"
                bgColor="white"
                color="gray.600"
                borderRadius="lg"
                borderWidth={1}
                borderColor="blueGray.50"
                pointerEvents="auto"
                closeOnClick
                closeOnMouseDown
                closeOnEsc
                openDelay={300}
                closeDelay={1500}
                // isDisabled={canLiveModeBeEnabled}
              >
                <Flex>
                  <Switch
                    size="sm"
                    defaultChecked={canLiveModeBeEnabled}
                    isChecked={isLiveMode}
                    isDisabled={!canLiveModeBeEnabled}
                    onChange={handleToggle}
                    _disabled={{
                      cursor: 'pointer !important',
                      _hover: {
                        cursor: 'pointer !important',
                      },
                    }}
                    css={{
                      span: {
                        background: colors.terracotta[500],
                        cursor: 'pointer',
                        transition: `background ${transitionDelay}s ease`,
                      },
                      '>span': {
                        background: colors.terracotta[100],
                        transition: `background ${transitionDelay}s ease`,
                      },
                      'span[data-checked]': {
                        background: colors.blue[700],
                        transition: `background ${transitionDelay}s ease`,
                      },
                      '>span[data-checked]': {
                        background: colors.blue[100],
                        transition: `background ${transitionDelay}s ease`,
                      },
                    }}
                  />
                </Flex>
              </Tooltip>
            </Flex>

            {!isLiveMode && (
              <Flex align="center" gridGap={2.5} h="full">
                <Divider orientation="vertical" h="4" />
                <Text color="white" fontSize="xs" transition={`display ${transitionDelay}s ease`}>
                  Credits: {credits ?? 0}
                </Text>
                <Button size="sm" w="4" h="4" minW={4} p={0} bgColor="white" borderRadius="full" onClick={onAddCredits}>
                  <AddIcon color="gray.800" boxSize={2} />
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

interface EnvironmentToggleHelper {
  isLiveEnabled: boolean;
  isWhitelisted: boolean;
  isLoading: boolean;
}

export const useEnvironmentToggle = (): EnvironmentToggleHelper => {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const { data: user, isFetching } = useAuthDetails();

  const isLiveEnabled = user?.isLiveEnabled || false;

  const isPathBlacklisted = (): boolean => {
    return ENV_TOGGLE_BLACKLIST.some(item => pathname.includes(item.id));
  };

  const isWhitelisted = !containsUUID(pathname) && !isPathBlacklisted();

  return { isLiveEnabled, isWhitelisted, isLoading: !isAuthenticated || isLoading || isFetching };
};

export default EnvironmentToggle;
