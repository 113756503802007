import { useEffect, useState } from 'react';
import { getAuthDetails } from '@/helpers/getAuthDetails';
import { useAuthDetails } from '@/queries/useAuth';
import { FeatureFlagDetail, FeatureFlags, UseFeatureFlag, WorkflowVersion } from '@/types/common';
import { FEATURE_FLAG_CHECK_BYPASSED_MENU_ITEMS } from '@/components/navigation/constants';

const initialFeatureFlag = {
  workflowVersion: {
    isWorkflowV2: false,
  },
  workflows: { delete: false, read: false, write: false },
  entities: { delete: false, read: false, write: false },
  lists: { delete: false, read: false, write: false },
  bulkVerification: { delete: false, read: false, write: false },
  customCapabilities: { delete: false, read: false, write: false },
  insights: { delete: false, read: false, write: false },
  demo: { delete: false, read: false, write: false },
  docs: { delete: false, read: false, write: false },
};

const useFeatureFlag = (): UseFeatureFlag => {
  const { data: user, isLoading } = useAuthDetails();
  const [featureFlag, setFeatureFlag] = useState<FeatureFlags>(initialFeatureFlag);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const authDetails = getAuthDetails();

  useEffect(() => {
    if (user || authDetails) {
      const flags = user?.feature_flag || authDetails?.feature_flag;
      setFeatureFlag(initialFlags => ({ ...initialFlags, ...flags }));
      setIsSuperAdmin(user?.is_super_admin || authDetails?.is_super_admin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getFeatureFlag = (
    page: keyof FeatureFlags,
    access: keyof FeatureFlagDetail | keyof WorkflowVersion,
  ): boolean => {
    const isFeatureFlagBypassed = FEATURE_FLAG_CHECK_BYPASSED_MENU_ITEMS.includes(page);
    if (page === 'bulkVerification' && authDetails.region === 'us') {
      return false;
    }
    if (page === 'workflowVersion' || access === 'isWorkflowV2') return featureFlag.workflowVersion?.isWorkflowV2;
    return isSuperAdmin || isFeatureFlagBypassed ? true : featureFlag[page]?.[access];
  };

  return { featureFlag, getFeatureFlag, isLoading };
};

export default useFeatureFlag;
