import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetCapabilityResponse } from '../types';

export const useCapabilityDetails = (id: string, enabled = true): UseQueryResult<GetCapabilityResponse, AxiosError> => {
  return useQuery('capabilityById', getCapabilityDetails(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled,
    select: capability => {
      // add column and node position
      const modifiedColumns = capability.object.columns.map((column, columnPosition) => {
        const nodes = column.nodes.map((node, nodePosition) => ({
          ...node,
          columnPosition: columnPosition + 1,
          nodePosition,
        }));
        return { ...column, nodes };
      });

      const modifiedObject = { ...capability.object, columns: modifiedColumns };

      return { ...capability, object: modifiedObject };
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getCapabilityDetails = (id: string) => {
  // const url = `${process.env.REACT_APP_BASE_URL}/capabilities/${id}`;
  const url = `/capabilities/${id}`;

  return async () => {
    const res = await bureauPlatformApi.get<GetCapabilityResponse>(url);
    return res.data;
  };
};

export default useCapabilityDetails;
