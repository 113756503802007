import { Box, Flex } from '@chakra-ui/react';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import SearchInput from '../search-input/SearchInput';

const SearchAndFilters: FC<{
  children: ReactNode;
  setSearchTerm?: Dispatch<SetStateAction<string>>;
}> = ({ children, setSearchTerm }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" gridGap="6">
      <Box w="full" visibility={setSearchTerm ? 'visible' : 'hidden'}>
        <SearchInput setSearchTerm={setSearchTerm || (() => undefined)} />
      </Box>
      {children}
    </Flex>
  );
};

const RHS: FC<{ children: ReactNode }> & {
  Filters: FC<{ children: ReactNode }>;
  Buttons: FC<{ children: ReactNode }>;
} = ({ children }) => (
  <Flex gridGap="6" alignItems="center" whiteSpace="nowrap">
    {children}
  </Flex>
);

RHS.Filters = ({ children }) => (
  <Flex gridGap="6" alignItems="center">
    {children}
  </Flex>
);

RHS.Buttons = ({ children }) => (
  <Flex gridGap="3" alignItems="center">
    {children}
  </Flex>
);

export { SearchAndFilters, RHS };
