import React from 'react';
import { HeaderWithTabs, Header, Heading } from '@/layout/Header';
import CustomTabs from '@/components/tabs/CustomTabs';
import WorkflowList from './WorkflowList';

const workflowTabList = [
  {
    label: 'My Workflows',
    id: 'workflows/my-workflows',
    content: <WorkflowList />,
  },
];

const WorkflowsTabs = (): React.ReactElement => {
  return (
    <HeaderWithTabs>
      <Header>
        <Heading>
          <Heading.Title>Workflows</Heading.Title>
        </Heading>
        {/*   <Flex>
        <Menu>
          <MenuButton
            d="none" // ! Display NONE
            as={Button}
            bg="white.50"
            color="purple.100"
            borderColor="blueGray.100"
            fontWeight="light"
            fontSize="sm"
            borderWidth={1}
          >
            <Flex justifyContent="center">
              <Image boxSize={5} src="/assets/icons/import.svg" mr={2} />
              Import
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              fontWeight={300}
              fontSize="sm"
              onClick={() => {
                setNameExistsError(false);
                setShowImportFilePopup(true);
              }}
            >
              Import a file
            </MenuItem>
            <MenuItem fontWeight={300} fontSize="sm" onClick={() => setShowCopyPasteJsonPopup(true)}>
              Copy/Paste JSON
            </MenuItem>
          </MenuList>
        </Menu>
        <CopyPasteJsonModal
          onClose={(workflowName: string, workflowJson: string) => {
            mutateAsync({
              name: workflowName,
              ...(workflowJson ? { workflowObject: JSON.parse(workflowJson) } : {}),
            });
            setApiError(false);
          }}
          onCancel={() => setShowCopyPasteJsonPopup(false)}
          nameExistError={nameExistsError}
          apiError={apiError}
          setNameExistError={setNameExistsError}
          showCopyPasteJsonPopup={showCopyPasteJsonPopup}
        />
        {showImportFilePopup && (
          <ImportFileModal
            onClose={(workflowName: string, workflowJson: string | ArrayBuffer) => {
              setNameExistsError(false);
              mutateAsync({
                name: workflowName,
                ...(workflowJson ? { workflowObject: JSON.parse(workflowJson as string) } : {}),
              });
            }}
            onCancel={() => setShowImportFilePopup(false)}
            errorMessage={nameExistsError ? 'A workflow with the given name already exists' : ''}
          />
        )}
      </Flex>
      <Button
        leftIcon={<Plus />}
        bg="purple.300"
        color="blue.500"
        borderRadius="lg"
        fontWeight="light"
        fontSize="sm"
        onClick={onCreateWorkflow}
        id="create-workflow"
      >
        New Workflow
      </Button>
      <Button
        leftIcon={<ListBullets />}
        backgroundColor="gray.200"
        borderRadius="lg"
        fontSize="sm"
        fontWeight="light"
        onClick={() => history.push('/blocklists')}
        id="create-workflow"
      >
        Lists
      </Button> */}
      </Header>
      <CustomTabs tabs={workflowTabList} />
    </HeaderWithTabs>
  );
};

export default WorkflowsTabs;
