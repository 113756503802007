import React, { ReactElement, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { setupAuth, setupOrganizationToken, isAuthTokenPresent } from '@/utils/api';
import { useAuthDetails } from '@/queries/useAuth';
import FactsLoader from '@/components/loader/Loader';
import { useFetchUserOrganization } from '@/screens/user-onboarding/queries/useFetchUserOrganization';
import VerifyEmail from '@/screens/user-onboarding/VerifyEmail';
import { useRecoilValue } from 'recoil';
import { EnvToggleAtom } from '@/states/envToggleAtom';
import { useQueryClient } from 'react-query';
import OverlayLoader from '@/components/OverlayLoader';
import { handleLogout, isSuperAdminUser } from '@/utils/auth';
import useIdleTimer from './hooks/useIdleTimer';

const IDLE_TIMEOUT = 30 * 60 * 1000;

const ProtectedRoute = (props: RouteProps): ReactElement => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { user: auth0User, isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading, logout } = useAuth0();

  const {
    refetch: refetchUserDetails,
    isLoading: isUserDetailsLoading,
    isFetching: isUserDetailsFetching,
  } = useAuthDetails();

  const {
    isLoading: isOrganisationLoading,
    isFetching: isOrgListFetching,
    refetch: refetchOrganisation,
  } = useFetchUserOrganization();
  // const { refetch: refetchDocsUrl } = useDocumentation();

  const toggledEnv = useRecoilValue(EnvToggleAtom);
  const isProd = toggledEnv === 'live';

  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const loadingOnToggle = isFetchingToken || isUserDetailsFetching || isOrgListFetching;

  const handleIdle = (): void => {
    handleLogout(logout);
  };

  // Use the custom idle timer hook with a 30-minute timeout
  useIdleTimer({ onIdle: handleIdle, timeout: IDLE_TIMEOUT });

  const refetchAuthAPICalls = (): void => {
    refetchOrganisation().then(res => {
      const organisationList = res.data;
      if (organisationList?.length) {
        setupOrganizationToken(organisationList[0].id);
        queryClient.invalidateQueries({
          predicate: query => {
            const queryKey = Array.isArray(query.queryKey) ? query.queryKey : [query.queryKey];
            return queryKey.some(key => key && pathname.includes(key));
          },
        });
        refetchUserDetails();
        // refetchDocsUrl();
      }
    });
  };

  useEffect(() => {
    if (!isAuthenticated && !(isSuperAdminUser() && isAuthTokenPresent())) {
      return;
    }

    if (isSuperAdminUser() && isAuthTokenPresent()) {
      refetchAuthAPICalls();
    }

    const getToken = async (): Promise<void> => {
      try {
        setIsFetchingToken(true);
        const token = await getAccessTokenSilently();

        setupAuth(token);
        setIsFetchingToken(false);

        refetchAuthAPICalls();

        // return token;
      } catch (error) {
        setIsFetchingToken(false);
      } finally {
        setIsFetchingToken(false);
      }
    };

    getToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isProd]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !isSuperAdminUser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const organization = urlParams.get('organization') || '';
      const invitation = urlParams.get('invitation') || '';

      if (!organization) {
        loginWithRedirect();
        return;
      }

      // Trigger the login with the organization parameter if available
      loginWithRedirect({
        organization, // Include organization ID if present
        invitation,
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // Logout Super Admin on refresh
  if (!isAuthenticated && isSuperAdminUser() && !isAuthTokenPresent()) {
    localStorage.clear();
  }

  if (
    (!isAuthenticated && !isSuperAdminUser()) ||
    isUserDetailsLoading ||
    isOrganisationLoading ||
    !isAuthTokenPresent()
  ) {
    return <FactsLoader />;
  }

  if (auth0User && !auth0User?.email_verified && !isSuperAdminUser()) {
    return <VerifyEmail />;
  }

  return (
    <>
      {loadingOnToggle && <OverlayLoader />}
      <Route {...props} />
    </>
  );
};
export default ProtectedRoute;
