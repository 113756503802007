import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { bureauPlatformApi } from '@/utils/api';
import { getSearchTerm, getFilterField, getSortField } from '../helpers/parameters';
import { WorkflowList, WorkflowFilterField, Workflow, WorkflowType } from '../types';

const pageLimit = 48;

const checkIfFilterApplied = (filterFields?: WorkflowFilterField): boolean => {
  return (
    (filterFields?.outcome && filterFields.outcome.length && filterFields.outcome[0] !== '') ||
    filterFields?.createdAt?.length !== 0
  );
};

export const useInfiniteWorkflowList = (
  workflowType = 'workflows' as WorkflowType,
  filterFields?: WorkflowFilterField,
  searchTerm?: string,
  offset = 0,
): UseInfiniteQueryResult<AxiosResponse<WorkflowList>, AxiosError> => {
  const queryKeys = [
    '/workflows',
    '/workflows/templates',
    '/workflows/my-workflows',
    'workflowList',
    workflowType,
    filterFields,
    searchTerm,
    offset,
  ];
  return useInfiniteQuery(
    queryKeys,
    ({ pageParam = 0 }) => fetchWorkflowList(workflowType, pageParam, filterFields, searchTerm),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      getNextPageParam: (lastPage, pages) => {
        const maxPages = Math.ceil(lastPage?.data?.count / pageLimit);
        const nextPage = pages.length;
        return nextPage < maxPages ? nextPage : undefined;
      },
      select: workflows => {
        const { pages } = workflows;
        let listData: Workflow[] = [];
        pages.forEach(page => {
          if (page?.data?.[workflowType])
            listData = [
              ...listData,
              ...page.data?.[workflowType].map(workflow => ({
                ...workflow,
                workflowId: workflow.templateId || workflow.workflowId,
              })),
            ];
        });
        const data = {
          ...workflows,
          listData,
          count: pages[0]?.data?.workflows?.length,
        };
        return data;
      },
    },
  );
};

const fetchWorkflowList = (
  endpoint: WorkflowType,
  pageParam: number,
  filterFields?: WorkflowFilterField,
  searchTerm?: string,
): Promise<AxiosResponse<WorkflowList>> => {
  const params = {
    limit: pageLimit,
    offset: pageParam * pageLimit,
    sortBy: getSortField(),
    ...(searchTerm && { searchTerm: getSearchTerm(searchTerm) }),
    ...(checkIfFilterApplied(filterFields) && { filter: getFilterField(filterFields) }),
  };
  const url = `/${endpoint}`;
  // if (endpoint === 'templates') {
  //   url = `${process.env.REACT_APP_BASE_URL}/${endpoint}`;
  // }

  const res = bureauPlatformApi.get<WorkflowList>(url, {
    params,
  });
  return res;
};
