import { useEffect, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { bureauPlatformApi } from '@/utils/api';
import { Capability, CapabilityList, CapabilityFilterField } from '../types';

const pageLimit = 500;

export const useInfiniteCapabilityList = (
  selectedUseCase = true,
  filterFields?: CapabilityFilterField,
  searchTerm?: string,
): UseInfiniteQueryResult<CapabilityList, AxiosError> => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [searchTerm]);
  return useInfiniteQuery(
    ['/capabilities', 'capabilityList', filterFields, searchTerm, offset],
    ({ pageParam = 0 }) => fetchCapabilityList(pageParam, filterFields, searchTerm),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: selectedUseCase,
      select: capabilities => {
        const { pages } = capabilities;
        let listData: Capability[] = [];
        pages.forEach(page => {
          if (page?.capabilities) listData = [...listData, ...page.capabilities] as Capability[];
        });
        const data = {
          ...capabilities,
          listData,
          count: pages[0]?.capabilities?.length,
        };
        return data;
      },
    },
  );
};

const fetchCapabilityList = async (
  pageParam: number,
  filterFields?: CapabilityFilterField,
  searchTerm?: string,
): Promise<CapabilityList> => {
  const params = {
    ...filterFields,
    limit: pageLimit,
    offset: pageParam * pageLimit,
    searchTerm,
  };

  // const url = `${process.env.REACT_APP_BASE_URL}/capabilities`;
  const url = `/capabilities`;

  const res = await bureauPlatformApi.get<CapabilityList>(url, {
    params,
  });
  return res.data;
};
