import React, { ReactElement, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Text, useToast, Image, Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import exportFromJSON from 'export-from-json';

import AddEditWorkflowModal from '@/screens/workflows/workflow-creation/components/AddEditWorkflowModal';
import useCreateWorkflow from '@/screens/workflows/workflow-creation/queries/useCreateWorkflow';
import { WorkflowJson, WorkflowModified } from '@/screens/workflows/workflow-creation/states/workflowChart';
import { newJson } from '@/screens/workflows/workflow-creation/constants';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import AlertMessageModal from '@/components/AlertMessageModal';
import useDebounce from '@/hooks/useDebounce';
import { CustomErrorCode, UserAction } from '@/types/common';
import { selectAllOption } from '@/constants/constants';
import { logEvent } from '@/analytics';
import { RHS, SearchAndFilters } from '@/components/platform-search-filter/SearchAndFilterWrapper';
import { Plus, ListBullets } from 'phosphor-react';
import { useInfiniteWorkflowList } from './queries/useWorkflowList';
import useGetWorkflow from './workflow-creation/queries/useGetWorkflow';
import { ListData, WorkflowFilterField, ToastMessage } from './types';
import { defaultWorkflowName, outcomeOptions, refetchDelay } from './constants';
import { ModifyWorkflowDetails } from './workflow-creation/types';
import useUpdateWorkflow from './workflow-creation/queries/useUpdateWorkflow';
import useDeleteWorkflow from './workflow-creation/queries/useDeleteWorkflow';
import AddDuplicateWorkflowModal from './workflow-creation/components/AddDuplicateWorkflowModal';
import TryOutModal from './components/try-out-modal/TryOutModal';
import { useTryOut } from './queries/useTryOut';
import { Parameter } from './components/try-out-modal/types';
import { formatCurrentTime } from './helpers';
import WorkFlowListContent from './components/WorkflowListContent';
import WorkflowSearchFilter from './components/WorkflowSearchFilter';

export const initialWorkflowDetails = {
  name: '',
  workflowId: '',
  active: false,
  isAsyncExecution: false,
};

export const initialTryOutDetails: Parameter[] = [{ parameter: '', value: '', type: '' }];

export const WorkflowList = (): ReactElement => {
  const [filterFields, setFilterFields] = useState<WorkflowFilterField>({
    outcome: [...outcomeOptions.map(item => item.value), selectAllOption.value],
    createdAt: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [nameExistsError, setNameExistsError] = useState(false);
  const [, setApiError] = useState(false);
  const [workflowDetails, setWorkflowDetails] = useState<ModifyWorkflowDetails>(initialWorkflowDetails);
  const [showImportFilePopup, setShowImportFilePopup] = useState(false);
  const [tryOutDetails, setTryOutDetails] = useState(initialTryOutDetails);
  const [tryOutResponse, setTryOutResponse] = useState('');
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [offset, setOffset] = useState(0);
  const [selectedAction, setSelectedAction] = useState('');
  const [showCopyPasteJsonPopup, setShowCopyPasteJsonPopup] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const setWorkflowModified = useSetRecoilState(WorkflowModified);

  useLayoutEffect(() => {
    document.title = 'Workflows';
  }, []);

  const searchItem = useDebounce(searchTerm);

  const { data, isLoading, isFetching, isError, refetch, fetchNextPage, hasNextPage } = useInfiniteWorkflowList(
    'workflows',
    filterFields,
    searchItem,
    offset,
  );
  const history = useHistory();
  const toast = useToast();

  const { data: workflowObject, refetch: refetchWorkflowDetails, isFetching: isDetailsFetching } = useGetWorkflow(
    workflowDetails.workflowId,
  );

  const { listData } = data ? (data as ListData) : { listData: [] };

  const { mutateAsync } = useCreateWorkflow({
    onSuccess: async response => {
      setIsButtonLoading(false);
      history.push(`/workflows/${response.workflowId}/create`);
      setWorkflowJson(newJson);
      if (showImportFilePopup) setShowImportFilePopup(false);
      if (showCopyPasteJsonPopup) {
        setShowCopyPasteJsonPopup(false);
        setWorkflowModified(true);
      }
    },
    onError: mutateError => handleModifyWorkflowError(mutateError),
  });

  const { mutateAsync: updateWorkflow } = useUpdateWorkflow({
    onSuccess: () => handleSuccess(ToastMessage.WORKFLOW_UPDATED_MESSAGE),
    onError: updateError => handleModifyWorkflowError(updateError),
  });

  const { mutateAsync: tryOutWorkflow } = useTryOut({
    onSuccess: response => {
      setTryOutResponse('success');
      setTransactionId(response?.transactionId);
      setSelectedAction('');
      setTryOutDetails(initialTryOutDetails);
    },
    onError: () => {
      setTryOutResponse('error');
      setSelectedAction('');
    },
  });

  const handleModifyWorkflowError = (updateError: AxiosError): void => {
    if (updateError?.response?.status === CustomErrorCode.RECORD_EXISTS) {
      setNameExistsError(true);
      setIsButtonLoading(false);
    } else {
      setApiError(true);
      handleErrorToast(updateError);
    }
    setIsButtonLoading(false);
  };

  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow({
    onSuccess: () => {
      handleSuccess(ToastMessage.WORKFLOW_DELETED_MESSAGE);
    },
    onError: deleteError => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleErrorToast(deleteError);
    },
  });

  const handleSuccess = (toastMessage: string): void => {
    setTimeout(() => {
      setIsButtonLoading(false);
      setSelectedAction('');
      handleSuccessToast(toastMessage);
      refetch();
    }, refetchDelay);
  };

  const setWorkflowJson = useSetRecoilState(WorkflowJson);

  const handleSuccessToast = (description: string): void => {
    toast({
      description,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleErrorToast = (mutateError: AxiosError): void => {
    toast({
      description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const onDuplicate = (details: ModifyWorkflowDetails): void => {
    mutateAsync({
      name: details?.name,
      workflowObject: details?.workflowObject,
    });
  };

  const onEditClose = (details: ModifyWorkflowDetails): void => {
    updateWorkflow({
      id: details?.workflowId,
      workflow: { name: details?.name, active: details.active, isAsyncExecution: details.isAsyncExecution },
    });
    setIsButtonLoading(true);
  };

  const onActionPopupCancel = (): void => {
    setSelectedAction('');
    setWorkflowDetails(initialWorkflowDetails);
  };

  const onDelete = (): void => {
    if (workflowDetails?.workflowId) {
      deleteWorkflow({ id: workflowDetails?.workflowId });
      setIsButtonLoading(true);
    }
  };

  const getTryOutDetails = (): { [key: string]: string } => {
    let response = {};
    tryOutDetails.forEach((parameter: Parameter) => {
      response = { ...response, [parameter.parameter]: parameter.value };
    });
    return response;
  };

  const onTryOutWorkflow = (): void => {
    const request = {
      workflowId: workflowDetails.workflowId,
      data: { ...getTryOutDetails() },
    };
    tryOutWorkflow(request);
  };

  const onCreateWorkflow = (): void => {
    mutateAsync({
      name: `${defaultWorkflowName}-${formatCurrentTime()}`,
    });
    setIsButtonLoading(true);
    logEvent('User Interaction', 'Button Click', 'Create Workflow V1');
  };

  useEffect(() => {
    if (workflowObject?.workflowId && !isDetailsFetching && isExportButtonClicked) {
      exportFromJSON({
        data: workflowObject?.workflowObject,
        fileName: workflowObject?.name,
        exportType: exportFromJSON?.types.json,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowObject, isDetailsFetching, isExportButtonClicked]);

  useEffect(() => {
    setOffset(0);
  }, [filterFields, searchItem]);

  useEffect(() => {
    if (selectedAction === UserAction.EXPORT && workflowDetails.workflowId) {
      setIsExportButtonClicked(true);
      refetchWorkflowDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction, workflowDetails]);

  const getResponseContent = useMemo(
    (): ReactElement => (
      <Flex direction="column" alignItems="center">
        <Image src={`/assets/icons/${tryOutResponse}_response.svg`} mb={4} />
        <Box textAlign="center" mt={2}>
          {tryOutResponse === 'success'
            ? 'Transaction successfully executed'
            : 'Some error occurred while executing the transaction. Please try again.'}
        </Box>
      </Flex>
    ),
    [tryOutResponse],
  );

  return (
    <Flex direction="column" h="full" gridGap="5" w="full" overflowY="auto" overflowX="hidden">
      <SearchAndFilters setSearchTerm={setSearchTerm}>
        <RHS>
          <RHS.Filters>
            <WorkflowSearchFilter
              filterFields={filterFields}
              setFilterFields={setFilterFields}
              setSearchTerm={setSearchTerm}
            />
          </RHS.Filters>
          <RHS.Buttons>
            <Button
              leftIcon={<ListBullets />}
              backgroundColor="gray.200"
              borderRadius="lg"
              fontSize="sm"
              fontWeight="light"
              onClick={() => history.push('/blocklists')}
              id="create-workflow"
            >
              Lists
            </Button>
            <Button
              leftIcon={<Plus />}
              bg="purple.300"
              color="blue.500"
              borderRadius="lg"
              fontWeight="light"
              fontSize="sm"
              onClick={onCreateWorkflow}
              id="create-workflow"
            >
              New Workflow
            </Button>
          </RHS.Buttons>
        </RHS>
      </SearchAndFilters>

      <Box h="calc(100% - 60px)" w="full">
        <WorkFlowListContent
          filterFields={filterFields}
          data={listData.filter(workflowData => workflowData.releaseType !== 'v2')}
          isFetching={isFetching}
          isLoading={isLoading}
          isError={isError}
          searchTerm={searchTerm}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          setSelectedAction={setSelectedAction}
          setWorkflowDetails={setWorkflowDetails}
          onCreateWorkflow={onCreateWorkflow}
          refetch={refetch}
        />
      </Box>
      {selectedAction === UserAction.EDIT && (
        <AddEditWorkflowModal
          details={workflowDetails}
          onClose={onEditClose}
          onCancel={onActionPopupCancel}
          editFromList
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
          isLoading={isButtonLoading}
        />
      )}
      {selectedAction === UserAction.DELETE && (
        <ConfirmationModal
          headerText="Delete Workflow"
          bodyText={
            <Box fontSize="sm" display="flex">
              Do you really want to delete{' '}
              <Text ml={1} color="blue.500" fontWeight={600} textDecoration="underline">
                {workflowDetails?.name}?
              </Text>
            </Box>
          }
          isLoading={isButtonLoading}
          onClose={onDelete}
          onCancel={onActionPopupCancel}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      )}
      {selectedAction === UserAction.DUPLICATE && (
        <AddDuplicateWorkflowModal
          details={workflowDetails}
          onClose={onDuplicate}
          onCancel={onActionPopupCancel}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
        />
      )}
      {selectedAction === UserAction.TRYOUT && (
        <TryOutModal
          onCancel={() => {
            setTryOutDetails(initialTryOutDetails);
            onActionPopupCancel();
          }}
          onClose={onTryOutWorkflow}
          workflowId={workflowDetails.workflowId}
          tryOutDetails={tryOutDetails}
          setTryOutDetails={setTryOutDetails}
        />
      )}
      {tryOutResponse && (
        <AlertMessageModal
          alertContent={getResponseContent}
          onCancel={() => setTryOutResponse('')}
          onClose={() => {
            if (tryOutResponse === 'success') {
              history.push(`/reports/${transactionId}`);
            } else setTryOutResponse('');
          }}
          {...(tryOutResponse === 'success' && { buttonLabel: 'View Transaction' })}
        />
      )}
    </Flex>
  );
};

export default WorkflowList;
