import React, { ReactElement, useLayoutEffect, useState } from 'react';
import {
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { SidebarItems } from '@/components/navigation/states';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import { Cell } from 'react-table';
import { useHistory, useLocation } from 'react-router-dom';
import { Header, Heading } from '@/layout/Header';
import { Plus } from 'phosphor-react';
import { RHS, SearchAndFilters } from '@/components/platform-search-filter/SearchAndFilterWrapper';
import { useGetWebhookList } from './queries/useGetWebhookList';
import { WebhooksContentProps, ToastMessage, Webhooks } from './types';
import { columns } from './columns';
import { AddWebhookDialog } from './components/AddWebhookDialog';
import { useGetWebhookEvents } from './queries/useGetWebhookEvents';
import { useToggleWebhook } from './queries/useToggleWebhookStatus';
import WorkflowErrorState from '../workflowv2/components/WorkflowErrorState';

export const selectAllOption = { label: 'All', value: '*' };
const WebhookListContent = ({ data, isLoading, isFetching, onWebhookToggle }: WebhooksContentProps): ReactElement => {
  useLayoutEffect(() => {
    document.title = 'Webhooks';
  }, []);

  const history = useHistory();
  const location = useLocation();

  const tableCellClickHandler = (e: React.MouseEvent, { row }: Cell, index: number): void => {
    const { values } = row;

    if (index !== 5) {
      history.push({
        pathname: `${location.pathname}/${values.webhookId?.id}`,
      });
    }
  };

  return (
    <Flex direction="column" h="full" bg="white" borderWidth={1} borderRadius="lg" overflowY="auto">
      <PaginatedTable
        columns={columns(data.length || 0, onWebhookToggle)}
        data={data}
        isLoading={isLoading || isFetching}
        title="No webhooks configured"
        onCellClick={tableCellClickHandler}
        hidePagination
      />
    </Flex>
  );
};

export const WebhooksList = (): ReactElement => {
  const [showActionDialog, setShowActionDialog] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const listHeading = useRecoilValue(SidebarItems);

  const { data, isLoading, isFetching, refetch, isError, isRefetchError } = useGetWebhookList();
  const { listData } = data?.data ? { listData: data?.data } : { listData: [] };

  const { data: webhookEvents } = useGetWebhookEvents();
  const toast = useToast();

  const { mutateAsync: toggleWebhookStatus } = useToggleWebhook({
    onSuccess: response => {
      setTimeout(() => {
        toast({
          description: ToastMessage.WEBHOOK_MODIFIED_MESSAGE,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        refetch();
      });

      refetch();
    },
    onError: error => {
      toast({
        description: error?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const onToggle = (val: string): void => {
    const getSingleWebhook: Webhooks | undefined = listData.find(webhook => webhook.id === val);
    if (val)
      toggleWebhookStatus({
        ...getSingleWebhook,
        disabled: !getSingleWebhook?.disabled,
      });
  };

  return (
    <>
      <Flex direction="column" w="full" overflowY="auto" overflowX="hidden">
        <Header>
          <Heading>
            <Heading.Title>{listHeading.webhooks}</Heading.Title>
            <Heading.SubHeader>&#40; {listData.length} items &#41;</Heading.SubHeader>
          </Heading>
        </Header>

        <Flex direction="column" gridGap="5" borderRadius="lg" h="calc(100% - 100px)" p={5}>
          <SearchAndFilters>
            <RHS>
              <RHS.Filters>
                <Button
                  bg="white.50"
                  color="purple.100"
                  borderColor="blueGray.100"
                  fontWeight="light"
                  fontSize="sm"
                  borderWidth={1}
                  onClick={onOpen}
                >
                  View Expected Events
                </Button>
                <Button
                  leftIcon={<Plus />}
                  bg="purple.300"
                  color="blue.500"
                  borderRadius="lg"
                  fontWeight="light"
                  fontSize="sm"
                  id="create-webhook"
                  onClick={() => setShowActionDialog(true)}
                >
                  Create Webhook
                </Button>
              </RHS.Filters>
            </RHS>
          </SearchAndFilters>

          {isRefetchError || isError ? (
            <Flex
              w="full"
              h="full"
              justifyContent="center"
              bg="white"
              borderWidth={1}
              borderRadius="lg"
              overflowY="auto"
              overflowX="hidden"
            >
              <WorkflowErrorState
                errorText="It seems there is a technical issue that is preventing data from loading."
                onRetry={() => {
                  refetch();
                }}
                styles={{ w: '64' }}
                isRetryEnabled
              />
            </Flex>
          ) : (
            <WebhookListContent
              data={listData}
              isFetching={isFetching}
              isLoading={isLoading}
              count={listData.length}
              limit="20"
              showCreateWebhookPopUp={setShowActionDialog}
              onWebhookToggle={onToggle}
            />
          )}
        </Flex>
      </Flex>
      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="4">
          <Text fontSize="lg" fontWeight="semibold">
            Events for Webhooks
          </Text>
          <Text fontWeight="light" fontSize="sm">
            Last Updated on 30 August 2023
          </Text>
          <ModalCloseButton />
          <ModalBody pt="8" px="0">
            <Flex w="full" direction="column" borderBottomWidth={1} pb="4" borderColor="white.50" justify="start">
              {webhookEvents?.eventTypes?.map(event => {
                const parts = event.split('.');
                const title = parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();

                return (
                  <Flex key={Math.random()} mb="4">
                    <Text color="gray.800" fontWeight="light" fontSize="sm" flex="1">
                      {title}
                    </Text>
                    <Text
                      color="gray.800"
                      bg="white.50"
                      borderWidth={1}
                      borderColor="gray.50"
                      px="2"
                      py="1"
                      fontSize="xs"
                      fontWeight="normal"
                      borderRadius="xl"
                    >
                      {event}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {showActionDialog && (
        <AddWebhookDialog webhookEvents={webhookEvents} setOpenDialog={setShowActionDialog} refetch={refetch} />
      )}
    </>
  );
};
