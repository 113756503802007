import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { bureauPlatformApi } from '@/utils/api';

export interface UserDetailsType {
  email: string;
  nickname: string;
  picture: string;
  user_id: string;
  user_name: string;
}

interface DecodedTokenType {
  [key: string]: string | UserDetailsType | string[] | number | boolean;
}
interface DecodedTokenReturnTypes {
  decodedToken: DecodedTokenType;
  isLoading: boolean;
  userDetails: UserDetailsType;
}

function useDecodedToken(): DecodedTokenReturnTypes {
  const [isLoading, setIsLoading] = useState(false);
  const [decodedToken, setDecodedToken] = useState<DecodedTokenType>({});
  const urlKey = 'https://overwatch.bureau.id';

  React.useEffect(() => {
    const authToken = bureauPlatformApi.defaults.headers.common.Authorization;
    const token = authToken ? authToken.split(' ')[1] : '';

    if (token) {
      const decodeToken: DecodedTokenType = jwt_decode(token);
      setDecodedToken(decodeToken);
      setIsLoading(false);
    }
  }, []);

  return { decodedToken, isLoading, userDetails: decodedToken[urlKey] as UserDetailsType };
}

export default useDecodedToken;
