import React, { SetStateAction } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  ModalFooter,
  Button,
  Text,
  ModalCloseButton,
  ModalHeader,
  Textarea,
  Select,
  Switch,
  Flex,
  VStack,
} from '@chakra-ui/react';
import ReactSelect, { StylesConfig } from 'react-select';
import * as Yup from 'yup';

import { useCapabilityFilters } from '@/screens/user-onboarding/queries/useCapabilityFilters';
import FactsLoader from '@/components/loader/Loader';
import useGetPlans from '@/screens/billing/queries/useGetPlans';
import { useFormik } from 'formik';
import { Capability, SaveCapabilityFormDataTypes } from '@/screens/capabilitiesV2/types';
import { colors } from '@/utils/colors';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useAuthDetails } from '@/queries/useAuth';
import { getAuthDetails } from '@/helpers/getAuthDetails';

type OptionType = { value: string; label: string };
type GroupType = { label: string; options: OptionType[] };
type IsMulti = true;

const reactSelectStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
  control: provided => ({
    ...provided,
    borderColor: colors.gray[200],
    borderRadius: '6px',
    padding: '0.25rem',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: '8px',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'gray.500',
    fontSize: '14px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const capabilityInitialValues = {
  id: '',
  name: '',
  description: '',
  object: { columns: [] },
  plansWithAccess: [],
  industry: [],
  geography: [],
  useCase: [],
  active: true,
  input: [],
  output: [],
  createdAt: 0,
  updatedAt: 0,
};

const SaveCapabilityModal = ({
  isOpen,
  setIsOpen,
  details = capabilityInitialValues,
  isLoading,
  onSaveCapability,
  nameExistsError,
  setNameExistsError,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  details?: Capability;
  isLoading: boolean;
  onSaveCapability: (details: Capability) => void;
  nameExistsError: boolean;
  setNameExistsError: (val: boolean) => void;
}): React.ReactElement => {
  const { data: capabilityFilters, isLoading: isFiltersListLoading } = useCapabilityFilters();
  const { data: pricingPlans, isLoading: isPlansLoading } = useGetPlans();
  const { data: meDetails, isLoading: isMeDatailsLoading } = useAuthDetails();
  const authDetails = getAuthDetails();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Capability Name must be at least 3 characters long.')
      .required('Name is required'),
    description: Yup.string()
      // .min(40, 'Description must have at least 40 characters.')
      .required('Description is required'),
    plansWithAccess: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one pricing plan must be selected')
      .required('At least one pricing plan must be selected'),
    industry: Yup.string().required('Industry is required'),
    geography: Yup.string().required('Country is required'),
    useCase: Yup.string().required('Lifecycle stage is required'),
  });

  const formik = useFormik<SaveCapabilityFormDataTypes>({
    initialValues: {
      ...details,
      plansWithAccess: details?.plansWithAccess?.filter(value => !!value),
      industry: details?.industry?.[0],
      geography: details?.geography?.[0],
      useCase: details?.useCase?.[0],
      description: details?.description,
    },
    validationSchema,
    onSubmit: (formData, { setSubmitting }) => {
      const { industry, geography, useCase } = formData;

      const capabilityPayload = {
        ...formData,
        industry: industry?.length ? [industry] : [],
        geography: geography?.length ? [geography] : [],
        useCase: useCase?.length ? [useCase] : [],
      };
      if (formik.isValid) {
        onSaveCapability(capabilityPayload);
      }

      setSubmitting(false);
    },
  });
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
      <ModalOverlay />

      <ModalContent w="md" my="4">
        <ModalHeader pt="6" pb="0">
          <Text mb="2" fontSize="md" fontWeight="500" color="gray.800" lineHeight="130%" sx={{ textWrap: 'balance' }}>
            Save as a Capability
          </Text>
          <Text fontSize="xs" fontWeight="300" color="gray.600">
            Once capability is saved then you can customize the availability and plan selection.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        {isFiltersListLoading || isPlansLoading || isMeDatailsLoading ? (
          <Flex h="588px" overflow="hidden" alignItems="center">
            <FactsLoader />
          </Flex>
        ) : (
          <form id="save-capability" onSubmit={formik.handleSubmit}>
            <ModalBody h="full" p="6">
              <VStack spacing={4} align="stretch">
                <FormControl isInvalid={!!formik.errors.name && !!formik.touched.name}>
                  <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                    Capability Name
                  </FormLabel>
                  <Input
                    borderColor={nameExistsError ? 'red.500' : 'inherit'}
                    _placeholder={{ fontSize: 'sm', fontWeight: 300, lineHeight: 'normal' }}
                    fontSize="sm"
                    type="text"
                    placeholder="Enter Capability Name"
                    name="name"
                    onChange={event => {
                      formik.handleChange(event);
                      setNameExistsError(false);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {nameExistsError && (
                    <FormHelperText fontSize="xs" color="red.500">
                      A capability with the given name already exists
                    </FormHelperText>
                  )}
                  {formik.errors.name && formik.touched.name && (
                    <FormHelperText fontSize="xs" color="red.500">
                      {formik.errors.name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!formik.errors.description && !!formik.touched.description}>
                  <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                    Description
                  </FormLabel>
                  <Textarea
                    _placeholder={{ fontSize: 'xs', fontWeight: 300, lineHeight: 'normal' }}
                    fontSize="sm"
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  />
                  {formik.errors.description && formik.touched.description && (
                    <FormHelperText fontSize="xs" color="red.500">
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </FormControl>

                <Flex gridGap="4">
                  <FormControl flex="0">
                    <FormLabel
                      htmlFor="is-active"
                      m="0"
                      fontSize="sm"
                      fontWeight="400"
                      lineHeight="normal"
                      color="gray.900"
                    >
                      Active
                    </FormLabel>
                    <Switch
                      name="active"
                      onChange={formik.handleChange}
                      isChecked={formik.values.active}
                      colorScheme="blue"
                      size="sm"
                      id="is-active"
                    />
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.geography && !!formik.touched.geography}>
                    <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                      Available Country
                    </FormLabel>
                    <Select
                      _placeholder={{ fontSize: 'sm', fontWeight: 300, lineHeight: 'normal', color: 'gray.500' }}
                      fontSize="sm"
                      type="select"
                      placeholder="Select Country"
                      name="geography"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.geography}
                    >
                      {capabilityFilters?.geographies
                        .filter(country => !country.comingSoon)
                        .map(country => (
                          <option key={country.value} value={country.value}>
                            {country.displayText}
                          </option>
                        ))}
                    </Select>
                    {formik.errors.geography && formik.touched.geography && (
                      <FormHelperText fontSize="xs" color="red.500">
                        {formik.errors.geography}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formik.errors.industry && !!formik.touched.industry}>
                    <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                      Industry
                    </FormLabel>
                    <Select
                      _placeholder={{ fontSize: 'sm', fontWeight: 300, lineHeight: 'normal', color: 'gray.500' }}
                      fontSize="sm"
                      type="text"
                      placeholder="Select Industry"
                      name="industry"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.industry}
                    >
                      {capabilityFilters?.industries.map(industry => (
                        <option key={industry.value} value={industry.value}>
                          {industry.displayText}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.industry && formik.touched.industry && (
                      <FormHelperText fontSize="xs" color="red.500">
                        {formik.errors.industry}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>

                <FormControl isInvalid={!!formik.errors.useCase && !!formik.touched.useCase}>
                  <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                    Select Lifecycle Stage
                  </FormLabel>
                  <Select
                    _placeholder={{ fontSize: 'sm', fontWeight: 300, lineHeight: 'normal' }}
                    fontSize="sm"
                    type="text"
                    placeholder="Select Digital Lifecycle Stage"
                    name="useCase"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.useCase}
                  >
                    {capabilityFilters?.useCases?.map(usecase => (
                      <option key={usecase.value} value={usecase.value}>
                        {usecase.displayText}
                      </option>
                    ))}
                  </Select>
                  {formik.errors.useCase && formik.touched.useCase && (
                    <FormHelperText fontSize="xs" color="red.500">
                      {formik.errors.useCase}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={!!formik.errors.plansWithAccess && !!formik.touched.plansWithAccess}>
                  <FormLabel fontSize="sm" fontWeight="400" lineHeight="normal" color="gray.900">
                    Pricing Plans &#40;s&#41;
                  </FormLabel>
                  <ReactSelect
                    isMulti
                    options={pricingPlans
                      ?.filter(
                        plan => plan.geography === meDetails?.geography || plan.geography === authDetails?.geography,
                      )
                      .map(plan => ({
                        value: plan.id,
                        label: plan.name,
                      }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="plansWithAccess"
                    placeholder="Select Pricing Plan(s)"
                    onChange={selectedOptions =>
                      formik.setFieldValue(
                        'plansWithAccess',
                        selectedOptions.map(option => option.value),
                      )
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values?.plansWithAccess?.map(planId => ({
                      value: planId,
                      label: pricingPlans?.find(plan => plan.id === planId)?.name || '',
                    }))}
                    styles={reactSelectStyles}
                    components={{ DropdownIndicator: () => <ChevronDownIcon boxSize="5" mr="2" /> }}
                  />
                  <FormHelperText fontSize="xs" fontWeight="300">
                    You may select multiple pricing plans.
                  </FormHelperText>
                  {formik.errors.plansWithAccess && formik.touched.plansWithAccess && (
                    <FormHelperText fontSize="xs" color="red.500">
                      {formik.errors.plansWithAccess}
                    </FormHelperText>
                  )}
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter p="4" w="full" borderTop="1px" borderColor="inherit">
              <Button
                type="submit"
                form="save-capability"
                fontSize="sm"
                fontWeight="300"
                px="4"
                py="3"
                colorScheme="blue"
                _hover={{ bg: 'blue.600' }}
                isLoading={isLoading}
              >
                Save Capability
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SaveCapabilityModal;
