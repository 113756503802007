import { useAuth0 } from '@auth0/auth0-react';
import { bureauPlatformApi } from '@/utils/api';
import { Flex } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuthDetails } from '@/queries/useAuth';
import { EnvToggleAtom } from '@/states/envToggleAtom';
import useFetchConfig from './queries/useFetchConfig';
import { ThemeAtom } from './states/themeAtom';
import ThemeCustomizationWrapper from './ThemeCustomizationWrapper';

function SDKPreview({
  openCustomiseTheme,
  workflowId,
}: {
  openCustomiseTheme: boolean;
  workflowId: string;
}): React.ReactElement {
  const toggledEnv = useRecoilValue(EnvToggleAtom);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { data: themeConfig } = useFetchConfig();
  const [themeData, setThemeState] = useRecoilState(ThemeAtom);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const { data: authDetails } = useAuthDetails();

  let baseUrl: string | undefined;
  if (toggledEnv === 'live') {
    baseUrl = process.env.REACT_APP_PROD_BASE_URL;
  } else {
    baseUrl = authDetails?.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : bureauPlatformApi.defaults.baseURL;
  }

  const [authData, setAuthData] = useState({
    token: '',
    orgId: bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
    baseUrl,
    environment: process.env.NODE_ENV,
  });

  useEffect(() => {
    if (isAuthenticated) {
      const getToken = async (): Promise<string> => {
        const token = await getAccessTokenSilently();
        setAuthData(prevAuthData => ({ ...prevAuthData, token }));
        return token;
      };
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (themeConfig) {
      setThemeState({
        themes: [themeConfig],
        currentIndex: 0,
      });
    }
  }, [themeConfig, setThemeState]);

  useEffect(() => {
    const iframe = document.querySelector('#preview_window') as HTMLIFrameElement;

    if (isIframeLoaded) {
      iframe?.contentWindow?.postMessage({ theme: themeData.themes[themeData.currentIndex], authData }, '*');
    }
  }, [themeData, isIframeLoaded, authData]);

  const options = {
    workflowId,
    type: 'INIT_DATA',
    authMetadata: authDetails,
    env: process.env.NODE_ENV,
    baseUrl: bureauPlatformApi.defaults.baseURL,
    webt: bureauPlatformApi.defaults.headers.common.Authorization,
    orgId: bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
  };

  const encodedOptions = window.btoa(JSON.stringify(options));
  return (
    <Flex h="full" p="6" gridGap="5">
      <Flex flex={2} h="full" borderRadius="2xl">
        <iframe
          style={{ width: '100%', height: '100%', borderRadius: 8 }}
          title="hostedFlow SDK"
          src={`${process.env.REACT_APP_TRYOUT_SDK_URL}/preview/?options=${encodedOptions}`}
          id="preview_window"
          onLoad={() => {
            // weird! need to wait for atleast 400ms even after iframe's onLoad to perform any action on iframe like postMessage
            setTimeout(() => setIsIframeLoaded(true), 400);
          }}
          // scrolling="no"
        />
      </Flex>
      {openCustomiseTheme && <ThemeCustomizationWrapper />}
    </Flex>
  );
}

export default SDKPreview;
