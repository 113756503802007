import React, { Dispatch, SetStateAction } from 'react';
import { Text } from '@chakra-ui/react';
import DateSelectBox from '@/components/date-select-box/DateSelectBox';
import { WorkflowFilterField } from '../types';

const WorkflowSearchFilter = ({
  filterFields,
  setSearchTerm,
  setFilterFields,
}: {
  filterFields: WorkflowFilterField;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setFilterFields: Dispatch<SetStateAction<WorkflowFilterField>>;
}): React.ReactElement => {
  const handleDateFilterChange = (startDate: string, endDate: string): void => {
    setFilterFields({ ...filterFields, createdAt: [startDate, endDate] });
  };

  return (
    <DateSelectBox
      selectedDates={filterFields.createdAt || []}
      onChange={handleDateFilterChange}
      menuHeading="Select time"
    >
      <Text pl={4} m={2} fontWeight={300} color="gray.600" fontSize="xs" id="workflow-date">
        Created Date:
      </Text>
    </DateSelectBox>
  );
};

export default WorkflowSearchFilter;
