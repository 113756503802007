import React, { Dispatch, SetStateAction } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import DateSelectBox from '@/components/date-select-box/DateSelectBox';
import { WorkflowFilterField } from '../types';

const WorkflowSearchFilter = ({
  filterFields,
  setFilterFields,
  workflowType,
}: {
  filterFields: WorkflowFilterField;
  setFilterFields: Dispatch<SetStateAction<WorkflowFilterField>>;
  workflowType: string;
}): React.ReactElement => {
  const handleDateFilterChange = (startDate: string, endDate: string): void => {
    setFilterFields({ ...filterFields, createdAt: [startDate, endDate] });
  };

  return (
    <Flex alignItems="center" gridGap="2">
      {workflowType !== 'templates' ? (
        <DateSelectBox
          selectedDates={filterFields.createdAt || []}
          onChange={handleDateFilterChange}
          menuHeading="Select time"
        >
          <Text fontWeight={300} color="gray.600" fontSize="xs" id="workflow-date" whiteSpace="nowrap">
            Created Date:
          </Text>
        </DateSelectBox>
      ) : (
        <Flex h="full" />
      )}
    </Flex>
  );
};

export default WorkflowSearchFilter;
